import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  active?: boolean
  category: string
  onClick?: (e: any) => void
  title: string
}

export const Filter = memo(function Filter({
  active = false,
  category,
  onClick,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {title}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0938rem;
  line-height: 1.1875rem;
  margin-right: 3.125rem;
  opacity: 0.4;
  position: relative;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.secondaryDark};
    opacity: 1;
  }
  &.active {
    opacity: 1;
    pointer-events: none;
  }

  @media (max-width: 1199px) {
    margin-right: 1.875rem;
  }
`
